export default {
  pageHeroV1: {
    container: {
      height: '100vh',
      width: '100%',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
      position: 'relative',
      padding: ['1rem', '', '2rem'],
      '::after': {
        content: "''",
        borderBottom: '6px solid',
        borderRight: '6px solid',
        borderRadius: '2px',
        borderColor: 'brandOrange',
        width: ['25px', '', '35px'],
        height: ['25px', '', '35px'],
        transform: 'rotate(45deg)',
        position: 'absolute',
        bottom: ['2rem', '', '3rem'],
        left: ['1rem', '2rem', '3rem']
      }
    },
    content: {
      flexDirection: 'column',
      alignItems: 'flex-end',
      color: 'white',
      textAlign: 'right',
      width: '90%',
      h1: {
        fontSize: ['1.5rem', '2rem', '2.5rem', '3.5rem'],
        marginBottom: 1
      },
      h2: {
        marginBottom: 4
      },
      button: {
        variant: 'buttons.multiLocation'
      }
    }
  }
}
