import buttons from './buttons'

export default {
  // ? ==========================
  // ? =====  Multilocation  ====
  // ? ==========================

  multiLocation: {},

  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {}, //! shadowed

  footer: {
    backgroundImage: 'url(https://www.transparenttextures.com/patterns/tileable-wood-colored.png)',
    position: 'relative',
    backgroundColor: '#98dbdf',
    color: 'white',
    '.hoursContainer': {
      display: 'none!important'
    },
    '.logo': {
      marginBottom: '1rem',
      position: 'relative',
      top: ['', '', '', '-1rem']
    },
    '.logoColumn': {
      border: 'none !important'
    },
    '.aboutText': {
      color: 'white'
    },
    '.mapColumn': {
      width: ['100%', '', '', '50%'],
      maxWidth: 'unset',
      iframe: {
        width: '100%',
        height: '400px',
        border: 'none',
        boxShadow: '-7px 7px #f58345'
      }
    },
    '.multiButtonContainer': {
      flexDirection: ['row', '', '', 'column'],
      a: {
        color: 'white'
      }
    },
    '.contactDetails-container .text': {
      fontSize: ['1rem', '1.25rem']
    }
  },

  ctaWidget: {
    display: 'none!important'
  },

  // ? ======================
  // ? ====  reuseables  ====
  // ? ======================

  title: {
    fontFamily: 'heading',
    color: 'primary',
    textTransform: 'capitalize',
    marginBottom: '2rem',
    fontSize: ['2rem', '', '2.5rem', '3rem'],
    order: '1',
    fontWeight: '400'
  },
  subtitle: {
    fontFamily: 'subheading',
    border: 'none',
    order: '2',
    fontWeight: 'normal',
    fontSize: ['1.75rem', '2rem', '2.25rem', '2.5rem']
  },
  text: {
    fontFamily: 'body',
    color: '#6a7375',
    order: '3',
    '> *': {
      fontSize: '1.2rem'
    }
  },

  ctaLink: {
    order: '4'
  },

  sideBySide1: {
    padding: ['', '', '', '5rem 2rem', '6rem 3rem'],
    '.lazyload-wrapper': {},
    '.content': {},
    '.title': {
      variant: 'customVariants.title',
      textShadow: '4px 3px white'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      color: 'white',
      textShadow: '2px 1px black'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      order: '5',
      '.ctaButton': {
        variant: 'buttons.primary'
      }
    }
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    backgroundPosition: 'bottom center',

    '.title': {
      fontSize: ['2rem', '3rem', '3.5rem', '4rem', '4.5rem'],
      fontWeight: '200',
      border: 'none',
      padding: '0rem'
    },
    '.subtitle': {
      color: 'primary'
    },
    a: {
      position: ['', '', '', 'absolute'],
      right: '0rem',
      bottom: '2rem'
    },

    '.section': {
      a: {
        position: ['', '', '', 'absolute'],
        right: '0rem',
        bottom: '2rem',
        '.ctaButton': {
          variant: 'buttons.primary'
        }
      }
    }
  },

  homepageHeroShout: {},

  homepageShout: {
    padding: ['1rem', '1rem', '3rem 2rem 12rem'],
    // marginBottom: '7rem',
    position: 'relative',
    // overflow: 'hidden',
    // zIndex: ['', '', '-1'],
    flexDirection: ['column', 'column', 'row'],
    '.imageContainer': {
      maxHeight: ['', '', '50vh'],
      position: ['', '', 'relative'],
      width: ['100%', '100%', '50%'],
      top: '1rem',
      left: ['0rem', '', '2rem', '6rem'],
      order: ['2', '', '1'],
      borderLeft: ['', '', 'solid 6px'],
      borderColor: ['', '', 'primary']
    },

    '.content': {
      position: ['', '', 'relative'],
      width: ['100%', '100%', '50%'],
      top: '6rem',
      left: '0rem',
      backgroundColor: '#e0f4fd',
      border: 'solid 7px',
      boxShadow: ['', '', '-20px -19px 0px 0px white'],
      borderColor: 'secondary',
      padding: ['', '', '', '2rem 5rem'],
      alignItems: 'flex-start',
      textAlign: 'left',
      order: ['1', '', '2']
    },
    '.title': {
      fontFamily: 'subheading',
      margin: '1rem 0rem',
      textAlign: 'left',
      fontSize: ['1.5rem', '', '2.5rem'],
      fontWeight: 'bold',
      color: 'secondary',
      position: ['', '', '', 'absolute'],
      left: ['', '', '', '-105%'],
      top: ['', '', '', '0%'],
      writingMode: ['', '', '', 'vertical-lr'],
      transform: ['', '', '', 'rotate(180deg)'],
      transformOrigin: ['', '', '', 'center'],
      width: ['', '', '', 'fit-content'],
      height: ['', '', '', 'fit-content']
    },
    '.date': {
      margin: '1rem 0rem',
      textAlign: 'left'
    },
    '.text': {
      fontFamily: 'heading',
      color: 'brandBlue',
      fontWeight: 'bold',
      margin: '1rem 0rem',
      textAlign: 'left'
    },
    '.shoutCTA': {
      variant: 'buttons.primary'
    }
  },

  homepageAboutSlider: {
    backgroundColor: 'background',
    margin: '0rem 0rem 3rem',
    padding: ['', '', '4rem 0rem'],
    backgroundImage: 'url(https://res.cloudinary.com/gonation/image/upload/sites/sam-the-clam/squid.png)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: ['50%', '', '50%'],
    backgroundPosition: ['top left', '', 'left center'],
    '.hero_content_container': {
      backgroundImage: 'url(https://res.cloudinary.com/gonation/image/upload/sites/sam-the-clam/fish3.png)',
      backgroundRepeat: 'no-repeat',
      backgroundSize: ['25%', '', '20%'],
      backgroundPosition: ['100% -100%', '', '100% -82%'],
      margin: ['', '', '2rem 0rem 6rem auto', '', '2rem 2rem 6rem auto'],
      position: 'static',
      padding: ['2rem 1rem', '', '0rem 1rem', '0rem 1rem 0rem 3rem', '0rem 1rem 0rem 5rem'],
      borderLeft: ['', '', '3px solid'],
      borderColor: ['', '', 'secondary']
    },
    '.title': {
      variant: 'customVariants.title',
      fontSize: ['2rem', '', '2.25rem', '2.5rem', '2.5rem', '3rem'],
      paddingLeft: ['', '', '1rem', '2rem', '3rem'],
      display: 'flex',
      border: ['', '', 'none'],
      alignItems: 'center',
      left: '0rem',
      top: '4rem',
      lineHeight: '1.5',
      marginBottom: ['1.5rem', '', '0rem'],
      maxWidth: 'unset',
      width: ['100%', '', '40%']
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      fontSize: ['1.75rem', '', '2rem'],
      whiteSpace: ['', '', '', 'nowrap'],
      fontWeight: 'bold'
    },
    '.text': {
      variant: 'customVariants.text',
      // textTransform: 'uppercase',
      fontSize: ['1rem', '', ''],
      color: '#76a2a9',
      maxWidth: '500px'
    },
    a: {
      order: '4',
      '.ctaButton': {
        variant: 'buttons.primary'
      }
    },

    '.slick-slider': {
      borderLeft: ['', '', '10px solid'],
      borderColor: ['', '', 'primary'],
      width: ['100%', '', '', '80%'],
      left: ['', '', '', '20%'],
      top: ['', '', '2rem'],
      '.slick-slide': {
        img: {
          filter: 'brightness(1)'
        }
      },
      '.slick-prev': {
        backgroundColor: 'secondary'
      },
      '.slick-next': {
        backgroundColor: '#bcd1e7',
        color: 'brandBlue'
      }
    }
  },

  homepageMenu: {
    variant: 'customVariants.sideBySide1',
    '.content': {
      width: ['', '', '50%'],
      backgroundImage:
        'url(https://res.cloudinary.com/gonation/image/upload/v1640804436/sites/sam-the-clam/Asset_3_4x.png)',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      '.title': {
        color: 'black'
      }
    },
    '.lazysideBySide1': { width: ['', '', '50%'] }
  },

  homepageGallery: {
    padding: ['1rem', '2rem', '3rem', '5rem 2rem', '6rem 3rem'],
    backgroundImage: 'url(https://www.transparenttextures.com/patterns/arches.png)',
    backgroundColor: 'primary',
    '.title': {
      variant: 'customVariants.title',
      color: 'secondary'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      color: 'white'
    },
    '.text': {
      variant: 'customVariants.text',
      color: 'white'
    },
    a: {
      order: '5',
      '.ctaButton': {
        variant: 'buttons.primary'
      }
    }
  },

  homepageEvents: {
    variant: 'customVariants.sideBySide1',
    padding: '4rem 1rem',
    '.title': {
      variant: 'customVariants.title'
      // color: 'white',
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      color: 'white'
    },
    '.text': {
      variant: 'customVariants.text',
      p: {
        color: 'white'
      },
      color: 'white'
    }
  },

  homepageContactSlider: {
    variant: 'customVariants.homepageAboutSlider',
    backgroundImage: 'url(https://res.cloudinary.com/gonation/image/upload/v1640803175/sites/sam-the-clam/crab.png)',
    backgroundSize: ['25%', '25%', '25%', '25%']
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    flexDirection: ['column', 'column', 'column', 'row'],
    padding: ['1rem', , '2rem'],
    '.lazyload-wrapper': {
      width: ['100%', '100%', '100%', '30%']
    },
    '.title': {
      color: 'grey',
      fontWeight: '400'
    },
    '.subtitle': {
      textTransform: 'uppercase',
      fontWeight: 'bold',
      letterSpacing: '2px',
      fontSize: '2rem',
      marginBottom: '2rem'
    },
    '.content': {
      order: ['', '', '', '1'],
      border: 'none',
      borderWidth: '5px',
      padding: ['1rem 0rem', , 8],
      width: ['100%', '100%', '100%', '68%'],
      margin: ['', '', '', 'auto 0 0']
    },

    // '.lazyload-wrapper::after': {
    //   content: "'Photo (c) Felix Angel'",
    // },
    '.image': {
      order: ['', '', '2'],
      width: ['100%', '100%', '100%', '100%'],
      maxHeight: '100vh'
    }
  },

  // ? ========================
  // ? ====  Menu page  ====
  // ? ========================

  menu: {
    padding: ['0rem', '0rem', '0rem', '0rem'],
    maxWidth: 'unset',
    '.innerMenuContainer': {
      padding: '0rem'
    },

    '.allInContainerWrapper': {
      maxWidth: '1200px',
      margin: '0 auto',
      padding: ['1rem', '0.5rem', '']
    },
    '.tabsRow': {
      // flexDirection: ['', '', '', 'column'],
      padding: '0rem',
      borderTop: 'solid 1px',
      borderColor: 'black',
      marginBottom: ['1rem', '2rem', '3rem']
    },
    '#menuTab': {
      margin: '0rem',
      border: 'none',
      padding: ['0.5rem 1rem', '', '0.75rem 1.25rem'],
      borderBottom: 'solid 1px',
      borderColor: 'black',
      flexGrow: '1',
      maxWidth: 'unset',
      minWidth: ['125px', '', '', '200px'],
      width: 'auto',
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
      fontFamily: 'heading',
      textTransform: 'uppercase',
      color: 'brandBlue'
    },
    '.menuSectionTitle': {
      color: 'brandBlue',
      fontSize: ['1.5rem', '1.75rem', '2rem']
    },
    '.menuSectionDescription': {
      color: 'primary'
    },
    '.menuItemName': {
      marginBottom: '0rem',
      textTransform: 'uppercase',
      color: 'brandBlue'
    },
    '.dropdownContainer': {
      display: 'none'
    }
  },

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {},

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.events-container': {
      maxWidth: 'unset'
    },

    '.special-events-container > .title, .recurring-events-container > .title': {
      display: 'none'
    },
    '.eventItemContent': {
      padding: ['1rem', '', '', '3rem', '4rem']
    },
    '.eventItemImage ': {
      maxHeight: 'unset'
    },

    '.eventItemTitle': {
      marginBottom: '1rem',
      color: 'primary',
      fontSize: ['', '', '', '2rem', '2.5rem']
    },
    '.eventItemDescription': {
      marginBottom: '1rem',
      color: 'brandBlue'
    },
    '.eventTime': {
      color: 'brandBlue'
    },
    '.eventDate': {
      color: 'primary'
    },
    '.eventMonth': {
      color: 'secondary'
    },

    '.eventCTABtns': {
      a: {
        variant: 'buttons.primary',
        fontFamily: 'heading'
      }
    },

    '.eventItemBtn ': {
      display: 'none'
    },

    // "event orders"

    '.eventItemContainer': {
      order: '2'
    },
    '.evt-UWf52nT8S1iJx3mCDYq5Sg': {
      order: '1'
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    order: '2',
    h2: {
      textTransform: 'uppercase',
      color: 'primary',
      marginBottom: '1rem'
    },
    // '.address, .phone': {
    //   display: 'none',
    // },
    backgroundPosition: 'center center',
    color: 'white',
    padding: ['3rem 1rem', '', '9rem 1rem'],
    '.text': {
      color: 'white'
    },
    '.title': {
      fontSize: ['1.5rem', '1.5rem', '1.7rem', '1.75rem'],
      lineHeight: '1.5',
      color: 'primary',
      fontWeight: '800'
    },
    '.inputField, .textField ': {
      fontWeight: '300',
      border: 'none',
      borderBottom: '1px solid',
      borderColor: 'secondary',
      borderWidth: '2px',
      borderRadius: '0px',
      padding: '1rem 0.5rem',
      color: 'white',
      backgroundColor: '#48c6e391',
      borderRadius: '5px',
      padding: '1.5rem 1rem',
      '::placeholder': {
        color: 'white'
      }
    },
    '.submitBtn': {
      variant: 'buttons.primary'
    }
  },

  locationMap: {
    '.title': {
      fontSize: ['1.25rem', '1.5rem', '1.7rem', '1.75rem'],
      color: 'primary',
      fontWeight: '800'
    },

    '.content_container': {
      color: 'brandBlue',
      backgroundImage: "url('https://res.cloudinary.com/gonation/image/upload/sites/sam-the-clam/fish2.png')",
      backgroundSize: '50%',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'left center'
    }
  }
}
