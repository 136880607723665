export default {
  heading: 'Silver Forte Grunge, sans-serif',
  subheading: 'Road Rage',
  body: 'Special Elite, sans-serif',
  monospace: 'monospace',
  display: 'Teko, serif',
  googleFonts: [
    'Montserrat:100,200,300,400,500,600,700,900,900',
    'Open Sans:100,200,300,400,500,600,700,900,900',
    'Road Rage',
    'Special Elite'
  ],
  customFamilies: ['Futura Light', 'Silver Forte Grunge', 'Neue Haas Grotesk Thin'],
  customUrls: [
    'https://www.gonation.biz/fonts/futura-light/futura-light.css',
    'https://www.gonation.biz/fonts/silver-forte-grunge/silver-forte-grunge.css',
    'https://www.gonation.biz/fonts/neue-haas-grotesk-thin/neue-haas-grotesk-thin.css'
  ]
}
