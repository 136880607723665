const variantName = 'instagramWidgetV1'

export default {
  [variantName]: {
    // ?============================
    // ?======  Containers =========
    // ?============================

    container: {
      flexDirection: ['column', '', 'column'],
      justifyContent: 'center',
      alignItems: 'center'
    },

    iFrameContainer: {
      width: ['100%', '', '']
    },

    logoAndTextContainer: {
      flexDirection: ['row', '', 'row'],
      width: ['100%', '', '50%'],
      alignItems: 'center',
      justifyContent: 'center',
      padding: '3rem 2rem',
      svg: {
        width: '50px',
        height: '50px',
        path: {
          fill: 'white'
        }
      }
    },

    // ?============================
    // ?======  Text Content =======
    // ?============================

    textContent: {
      flexDirection: 'column',
      justifyContent: 'center',
      padding: '1rem 0.25rem 1rem 1rem',
      textAlign: ['', '', 'center']
    },
    title: {
      fontSize: '1.5rem'
      // marginBottom: '1rem',
    },
    subtitle: {
      fontSize: '1.25rem'
    }
  }
}
