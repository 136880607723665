const variantName = 'navigationV11'

export default {
  [variantName]: {
    // ?============================
    // ?======== Container =========
    // ?============================

    container: {
      position: 'fixed',
      width: '100%',
      top: '0px',
      left: '0px',
      zIndex: '1000',
      boxSizing: 'border-box',
      // height: ['75px', '75px', '75px', 'unset'],
      padding: ['0.5rem', '', '', '0rem 2rem', '0rem 2rem', '0rem 2rem'],
      display: 'flex',
      transition: 'all ease-in-out 0.5s',
      alignItems: 'center',
      justifyContent: ['flex-start', 'center', '', 'center'],
      color: 'light',
      '.seperatorLine': {
        width: '100%',
        display: 'none'
        // maxWidth: '600px',
      },
      '.socialContainer a svg': {
        width: '16px',
        height: '16px'
      }
    },
    containerScrolled: {
      variant: `${variantName}.container`,
      // height: ['75px', '75px', '75px', 'unset'],
      padding: ['0.5rem', '', '', '0.5rem'],
      justifyContent: ['flex-start', 'center', 'center', 'center'],
      backgroundColor: 'white',
      boxShadow: '2px 2px 8px black',
      '.smallNavMenu': {
        '.navItem': {
          a: {
            color: 'black'
          }
        }
      }
    },

    // ?============================
    // ?=======  Nav Logos =========
    // ?============================

    logoLocationContainer: {
      flexDirection: 'column',
      alignItems: 'center',
      padding: ['0.25rem 0rem', '', '', '0.25rem 0.75rem', '0.25rem 2rem'],
      height: '100%',
      textAlign: 'center',
      display: ['inline-block', '', '', '']
    },

    logo: {
      zIndex: '2000',
      transition: 'all ease-in-out 0.4s',
      justifyContent: 'center',
      a: {
        display: 'flex',
        alignItems: 'center'
      },
      img: {
        // opacity: '0.85',
        // filter: 'brightness(0) invert(1)',
        maxHeight: ['140px', '150px', '200px', '200px'],
        transition: 'all ease-in-out 0.5s',
        ':hover': {
          // filter: 'brightness(0.75) invert(1)',
        }
      }
    },
    logoScrolled: {
      variant: `${variantName}.logo`,
      img: {
        maxHeight: ['60px', '70px', '80px', '90px']
      }
    },

    navMenuLogo: {
      img: {
        maxHeight: ['120px', '', '', '']
        // filter: 'brightness(1) invert(1)',
      },
      // marginBottom: ['1rem', '', '', ''],
      padding: ['1rem', '2rem'],
      background: '#f5f1e8',
      width: ['', '', '', '100%'],
      borderBottom: 'solid 2px',
      borderColor: 'secondary'
    },

    // ?============================
    // ?=======  Hamburger =========
    // ?============================

    hamburger: {
      display: ['flex', 'flex', 'flex', ''],
      zIndex: '2000',
      alignSelf: 'center',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: '35px',
      height: '25px',
      cursor: 'pointer',
      margin: ['0 0.5rem 0 auto', '', '', '0rem 2rem', '0rem 2.5rem'],
      minWidth: '30px',
      position: 'absolute',
      right: '0',
      div: {
        alignSelf: 'flex-end',
        transition: 'all ease-in-out 0.3s',
        height: '4px',
        width: '100%',
        backgroundColor: 'secondary',
        borderRadius: '10px'
      }
    },
    hamburgerOpen: {
      variant: `${variantName}.hamburger`,
      height: 'unset',
      div: {
        position: 'absolute',
        boxShadow: 'none',
        transition: 'all ease-in-out 0.3s',
        height: '4px',
        width: '100%',
        backgroundColor: 'secondary',
        borderRadius: '10px'
      },
      '& > div:nth-of-type(1)': {
        transform: 'rotate(135deg) '
      },
      '& > div:nth-of-type(2)': {
        transform: 'rotate(-135deg)'
      }
    },

    // ?============================
    // ?========  Nav Menu =========
    // ?============================

    navMenu: {
      position: ['absolute', 'absolute', 'absolute'],
      margin: '0px',
      boxSizing: 'border-box',
      justifyContent: ['flex-start', 'flex-start', 'flex-start'],
      backgroundColor: ['primary'],
      top: '0px',
      zIndex: '1000',
      width: ['80%', '60%', '40%', '30%'],
      height: '100vh',
      minWidth: '250px',
      left: '-120%',
      transition: 'all ease-in-out 0.5s',
      padding: ['0rem'],
      alignItems: ['center', 'center', 'center', 'flex-end'],
      textAlign: 'center',
      display: 'flex',
      flexDirection: ['column', 'column', 'column'],
      overflow: 'hidden',
      listStyleType: 'none',
      '.navItemDropdown': {
        margin: '0rem 0rem 0rem auto'
      },
      '.navItemDropdownMenu ': {
        margin: '0rem 0rem 0rem auto',
        position: 'static',
        a: {
          margin: '0rem 0rem 0rem auto'
        }
      }
    },

    // when nav is open it uses this variant
    navMenuOpen: {
      variant: `${variantName}.navMenu`,
      left: '0%'
    },

    navItem: {
      // margin: [],
      zIndex: '100',
      listStyleType: 'none',
      textAlign: 'right',
      width: '100%',
      flexGrow: '1',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      a: {
        padding: ['0.5rem 1rem', '0.75rem'],
        fontFamily: 'heading',
        fontSize: ['1rem', '', '1rem', '1.1rem'],
        textTransform: 'uppercase',
        fontWeight: '400',
        letterSpacing: '2px',
        textDecoration: 'none'
      },
      ':hover': {
        color: 'secondary'
      }
    },

    smallNavMenuLeft: {
      display: ['none', '', '', 'flex'],
      justifyContent: 'flex-end',
      flex: '1',

      '.navItem a': {
        fontSize: ['', '', '', '1rem', '1rem', '1.2rem'],
        padding: '0.5rem'
      }
    },

    smallNavMenuRight: {
      display: ['none', '', '', 'flex'],
      justifyContent: 'flex-start',
      flex: '1',

      '.navItem a': {
        fontSize: ['', '', '', '1rem', '1rem', '1.2rem'],
        padding: '0.5rem'
      }
    },

    smallNavHeading: {
      fontSize: ['0.75rem', '1rem', '1.25rem'],
      marginBottom: '0.5rem',
      fontWeight: '400'
    },

    navBlock: {
      display: 'flex',
      backgroundColor: 'rgba(255, 255, 255, 0.7)',
      position: 'absolute',
      margin: '0rem',
      zIndex: '500',
      transition: 'all ease-in-out 0.5s',
      top: '0rem',
      right: '-200%',
      width: '100%',
      height: '100vh'
    },

    navBlockOpen: {
      variant: `${variantName}.navBlock`,
      right: '0%'
    },

    // ?==========================
    // ?====  Dropdown Styles ====
    // ?==========================

    navItemDropdown: {
      alignItems: 'inherit',
      fontFamily: 'inherit',
      justifyContent: 'inherit',
      position: 'relative',
      cursor: 'pointer'
    },

    subNavItemDropdown: {
      variant: `${variantName}.navItem`,
      padding: '0.5rem',
      margin: ['0rem', '0rem', '0rem', '0rem'],
      whiteSpace: 'nowrap',
      a: {
        margin: '0rem auto 0rem 0rem',
        fontWeight: 'normal'
      }
    },

    navItemDropdownMenu: {
      padding: '0.5rem',
      textShadow: '0px 0px 30px rgb(122 122 122 / 79%)',
      position: ['', '', '', 'absolute'],
      display: 'flex',
      alignItems: 'inherit',
      justifyContent: 'inherit',
      flexDirection: 'column',
      width: 'fit-content',
      backgroundColor: 'primary'
    },

    // ?==========================
    // ?=====  Nav Widgets  ======
    // ?==========================

    locationSwitcher: {
      backgroundColor: '',
      zIndex: '2000',
      alignSelf: 'flex-start',
      marginTop: '0.5rem',
      button: {
        backgroundColor: 'unset',
        borderTop: '1px solid',
        borderRadius: '0px'
      },
      '.popUp': {
        transform: ['', '', '', 'translateX(-45%)']
      },
      '.popUp .content': {
        backgroundColor: 'rgb(255 255 255 / 74%)'
      }
    },

    // socialIconsContainerDesktop: {
    //   display: ['none', '', '', 'flex'],
    //   padding: '0.5rem 1rem',
    //   flexDirection: 'column',
    //   alignItems: 'center',
    //   textAlign: 'center',

    //   margin: ['', '', '', '0 auto 0 0'],
    //   a: {
    //     display: 'flex',
    //     textAlign: 'center',
    //     padding: '0.5rem',
    //     alignItems: 'center',
    //     transition: 'all ease-in-out 0.5s',
    //     ':hover': {
    //       transform: 'scale(1.5)',
    //       svg: {
    //         width: '16px',
    //         height: '16px',
    //         path: { fill: 'secondary' },
    //       },
    //     },
    //   },
    // },

    phoneContainerDesktop: {
      display: ['none', '', '', 'block'],
      borderRight: 'solid 1px',
      borderColor: 'secondary',
      paddingRight: '0.75rem',
      a: {
        transition: 'all ease-in-out 0.5s',
        ':hover': {
          color: 'secondary',
          fontSize: '1.1rem'
        }
      }
    },

    phoneSocialContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: ['center', '', '', 'flex-end'],
      flexDirection: ['column', '', '', 'column'],
      width: '100%',
      backgroundColor: 'secondary',
      flexGrow: '1',
      maxHeight: ['175px', '300px'],
      margin: 'auto 0 0'
    },

    phoneContainer: {
      display: 'flex',
      padding: ['0.5rem', '1rem'],
      width: '100%',
      flexDirection: 'column',
      alignItems: 'flex-end',
      justifyContent: 'center',
      textAlign: 'center',
      flexGrow: '1',
      borderTop: '2px solid',
      borderColor: 'black',
      a: {
        display: 'flex',
        padding: '0.5rem',
        alignItems: 'center',
        fontSize: '0.8rem',
        textAlign: 'center',
        justifyContent: 'center',
        svg: {
          marginRight: '0.25rem'
        }
      }
    },

    socialIconsContainer: {
      display: 'flex',
      padding: ['0.5rem', '1rem'],
      flexDirection: 'column',
      alignItems: 'flex-end',
      justifyContent: 'center',
      width: '100%',
      textAlign: 'center',
      backgroundColor: 'primary',
      flexGrow: '1',
      borderTop: '2px solid',
      borderColor: '#f58345',
      a: {
        display: 'flex',
        textAlign: 'center',
        padding: '0.5rem',
        alignItems: 'center'
      }
    }
  }
}
