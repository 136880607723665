import { keyframes } from '@emotion/react'

const variantName = 'heroV7'

export default {
  [variantName]: {
    // ?============================
    // ?======  Containers =========
    // ?============================
    hero: {
      height: ['', '', '', '100vh'],
      width: '100%',
      backgroundPosition: 'center',
      justifyContent: 'flex-end',
      alignItems: 'flex-start',
      position: 'relative',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      flexDirection: 'column'
    },

    // ?============================
    // ?====  Video Container  =====
    // ?============================

    videoContainer: {
      width: '100%',
      height: ['50vh', '', '', '100vh'],
      overflow: 'hidden',
      position: 'relative',
      zIndex: '5',
      background: '#000',
      video: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        minWidth: ['', '', '', '100%'],
        minHeight: ['', '', '', '100%'],
        width: 'auto',
        height: ['100%', '', '', 'auto'],
        zIndex: '99',
        transform: 'translateX(-50%) translateY(-50%)'
      },
      ':before': {
        position: ['', '', '', 'absolute'],
        top: '0px',
        bottom: '0px',
        left: '0px',
        right: '0px',
        background: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.1))',
        zIndex: '100',
        content: "''",
        width: '100%',
        height: '100%'
      }
    },

    // ?============================
    // ?======  Mute Styles ========
    // ?============================

    muteToggle: {
      position: 'absolute',
      top: ['calc(50vh - 3rem)', '', '', 'unset'],
      bottom: ['', '', '', '1rem'],
      right: ['1rem', '', '', '5rem'],
      fontSize: '2rem',
      zIndex: '10',
      color: 'white',
      opacity: '0.7',
      transition: 'all ease-in-out 0.4s'
    },

    //* this is need as the cta widget gets in the way so on scroll it moves to the right
    muteToggleScrolled: {
      variant: `${variantName}.muteToggle`,
      right: ['1rem', '', '', '1rem'],
      top: ['unset'],
      bottom: '1rem'
    },

    // ?============================
    // ?======  Box Content ========
    // ?============================

    content: {
      margin: ['1rem', '', '', '1rem'],
      padding: ['1rem'],
      borderLeft: 'solid 4px',
      borderColor: 'primary',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      position: ['', '', '', 'absolute'],
      color: ['text', '', '', 'light'],
      zIndex: '100',
      width: ['calc(100% - 1rem)', '', '', '50%'],
      a: {
        display: 'flex',
        width: 'fit-content',
        alignSelf: ['flex-end', '', '', 'flex-start']
      }
    },

    // ! Commented this out as video hero component doesn't use an image
    // ! as far as im aware. this needs checking tho
    // image: {
    //   height: '100%',
    //   width: '100%',
    //   objectFit: 'cover',
    // },

    // ?============================
    // ?=========  Logos  ==========
    // ?============================

    logoHero: {
      maxHeight: ['175px', '', '200px', '250px'],
      marginBottom: 3
    },

    // ?============================
    // ?======  Text Content =======
    // ?============================

    title: {
      fontSize: 8,
      marginBottom: 3
    },
    subtitle: {
      fontSize: 5,
      fontWeight: 'normal',
      marginBottom: 3
    },
    text: {},

    // ?============================
    // ?========  Buttons  =========
    // ?============================

    ctaButton: {
      marginTop: 3,
      padding: '0.75rem 1.5rem',
      borderBottom: '3px solid'
    },

    // ?============================
    // ?=======  Hero Shout  =======
    // ?============================

    shoutWidget: {
      container: {
        width: '100%',
        alignItems: 'stretch',
        backgroundColor: 'background',
        padding: 0,
        maxWidth: '500px',
        display: 'flex',
        bottom: '1rem',
        left: '1rem'
      },
      content: {
        order: '2',
        flexDirection: 'column',
        justifyContent: 'center',
        flexGrow: '1',
        paddingLeft: 2,
        paddingRight: 1,
        paddingY: 1,
        width: '80%',
        color: 'text'
      },
      title: {
        fontSize: 3,
        fontWeight: 'bold',
        color: 'secondary'
      },
      text: {
        fontSize: '0.8rem',
        marginY: 1
      },
      date: {
        fontSize: 2,
        color: 'secondary',
        fontWeight: 'bold'
      },
      imageContainer: {
        order: '1',
        width: '35%',
        img: {
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          border: 'solid 3px',
          borderColor: 'secondary'
        }
      }
    }
  }
}
