const variantName = 'successPage'

export default {
  [variantName]: {
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      width: '100vw',
      backgroundColor: 'background'
    },

    coverContainer: {
      display: ['', '', '', 'block'],
      position: ['absolute', '', '', 'static'],
      width: ['100%', '', '', '50%'],
      height: '100vh',
      zIndex: '0',
      filter: 'brightness(0.4)'
    },
    cover: {
      height: '100%',
      width: '100%',
      objectFit: 'cover'
    },

    content: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      padding: '1rem',
      textAlign: 'center',
      color: ['white', '', '', 'text'],
      width: ['', '', '', '50%'],
      backgroundColor: 'transparent',
      zIndex: 10
    },
    logo: {
      maxWidth: '150px',
      width: '100%'
    },
    title: {
      fontSize: '5rem',
      opacity: 0.65,
      marginBottom: '1rem'
    },
    subtitle: {
      fontSize: '1.75rem',
      marginBottom: '1rem',
      color: ['white', '', '', 'text']
    },
    text: {
      fontSize: '1rem',
      marginBottom: '1rem',
      color: ['white', '', '', 'text']
    },
    buttonContainer: {
      a: {
        variant: 'buttons.primary'
      }
    }
  }
}
