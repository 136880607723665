export default {
  primary: {
    backgroundColor: 'transparent',
    fontFamily: 'heading',
    color: 'white',
    fontWeight: 'normal',
    backgroundSize: '90% 100%',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundImage:
      'url(https://res.cloudinary.com/gonation/image/upload/v1640797883/sites/sam-the-clam/buttonbg.png)',
    padding: '1.5rem 3rem',
    fontSize: '1rem',
    textDecoration: 'none',
    textTransform: 'uppercase',
    transition: 'all ease-in-out 1s',
    opacity: '1',
    border: 'none',
    ':hover': {
      // backgroundColor: 'transparent',
      backgroundColor: 'transparent',
      backgroundSize: '100% 100%',
      color: 'light'
    }
  },
  secondary: {
    backgroundColor: 'transparent',
    color: 'primary',
    border: 'solid 2px',
    borderColor: 'primary',
    ':hover': {
      backgroundColor: 'primary',
      color: 'white'
    }
  }
}
