const variantName = 'contentBoxesV5'

export default {
  [variantName]: {
    // ?============================
    // ?======  Containers =========
    // ?============================
    container: {
      padding: ['3rem 1rem', '3rem 1rem', '3rem 1rem', '3rem 3rem']
    },

    // ?============================
    // ?======  Text Content =======
    // ?============================

    title: {
      variant: 'text.title'
    },
    subtitle: {
      variant: 'text.subtitle'
    },
    text: {
      variant: 'text.text'
    },

    // ?============================
    // ?========  Images  ==========
    // ?============================

    image: {
      width: '100%'
    },

    // ?============================
    // ?=========  Boxes  ==========
    // ?============================
    contentBoxes: {
      flexWrap: 'wrap',
      justifyContent: 'center',
      margin: '5rem auto',
      width: '100%',
      maxWidth: '1600px'
    },
    box: {
      flexGrow: '1',
      height: ['60vh', '', '', '50vh'],
      display: 'flex',
      flexDirection: 'column',
      width: [
        'calc(100% - 0.5rem * 2)',
        'calc(100% - 0.5rem * 2)',
        'calc(50% - 2rem * 2)',
        'calc(50% - 1.5rem * 2)',
        'calc(33.3% - 2rem * 2)',
        'calc(25% - 2.5rem * 2)'
      ],
      margin: ['0.5rem', '', '2rem', '1.5rem', '2rem', '2.5rem'],
      borderStyle: 'solid 1px',
      borderColor: 'grey',
      borderRadius: '10px',
      position: 'relative',
      transform: 'perspective(1000px)',
      WebkitTransformStyle: 'preserve-3d',
      transformStyle: 'preserve-3d',
      WebkitTransition: '0.6s',
      transition: '0.6s',
      ':hover': {
        '.frontCard': {
          WebkitTransform: 'rotateY(180deg)',
          transform: 'rotateY(180deg)'
        },
        '.backCard': {
          WebkitTransform: 'rotateY(0deg)',
          transform: 'rotateY(0deg)'
        }
      }
    },

    frontCard: {
      transform: 'rotateY(0deg)',
      WebkitBackfaceVisibility: 'hidden',
      backfaceVisibility: 'hidden',
      transformStyle: 'preserve-3d',
      height: '100%',
      width: '100%',
      position: 'relative',
      transform: 'rotateY(0deg)',
      background: 'transparent',
      zIndex: '100',
      transitionDuration: '0.6s',
      p: ['1.5rem', '', '2rem'],
      '.title': {
        position: 'absolute',
        fontSize: ['2rem', '2rem', '1.75rem', '2rem'],
        color: 'white',
        top: '40%',
        left: '1.5rem'
      },
      '.image': {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        height: '100%',
        objectFit: 'cover',
        width: '100%',
        borderRadius: '10px',
        filter: 'brightness(0.8)'
      }
    },
    backCard: {
      p: ['1.5rem', '', '2rem'],
      top: '0',
      left: '0',
      height: '100%',
      position: 'absolute',
      width: '100%',
      WebkitBackfaceVisibility: 'hidden',
      backfaceVisibility: 'hidden',
      WebkitTransform: 'rotateY(-180deg)',
      transform: 'rotateY(-180deg)',
      transformStyle: 'preserve-3d',
      transitionDuration: '0.6s',
      borderRadius: '10px',
      border: 'solid 1px #ababab57',
      '.title': {
        mb: '2rem'
      },
      '.text': {
        mb: '2rem'
      }
    },

    // ?============================
    // ?========  Buttons  =========
    // ?============================

    ctaButton: {
      variant: 'buttons.primary'
    },
    secondaryCtaButton: {
      variant: 'buttons.secondary'
    }
  }
}
